<template>
  <div class="search">
       <!-- 搜索 -->
      <div class="search">
        <template>
           <van-icon name="arrow-left" size="18" @click="black"/>
        </template>
        <van-search v-model="search" placeholder="输入搜索内容" shape="round" @search="handsearch" />
      </div>

      <van-list
        v-model="loading"
        :finished="finished"
        @load="handsearch"
      >
        <div class="text">搜索结果({{datalist.total}})</div>

        <div class="kong-content clearfix" v-if="show==true">
          <div>
            <img src="../assets/image/search-kong.png" alt="">
            <p>暂无搜索结果</p>
          </div>
        </div>

        <div class="content clearfix" v-if="show1==true">
          <div class="item" v-for="(item,index) in list" :key="index" @click="jump(item.id)">
              <div class="image">
                <img :src="`${https}${item.picList[0].picPath}`" alt="">
              </div>
              <div class="txt">
                <p class="title">{{item.productName}}</p>
<!--                <p class="introduction">{{item.labelList[0].labelContent}}</p>-->
                <p class="price">
                  <span>￥<span>{{item.specialPrices==null?item.productPrices:item.specialPrices}}</span></span>
                  <span class="last" v-show="item.specialPrices">￥{{item.productPrices}}</span>
                </p>
              </div>
          </div>
        </div>

      </van-list>
  </div>
</template>

<script>
import Vue from "vue";
import { Search } from "vant";
Vue.use(Search);

import { getSearch } from "@api/servicemall.js"
  export default {
    data(){
      return{
         search:'',
         loading : false,
         finished : false,
         list:'',
         datalist:'',
         https:'https://p.writemall.com/mallFile/product/',
         show:false,
         show1:false,
      }
    },
    methods:{
       //跳转详情
        jump(id){
          let numReg = /^[0-9]*$/
          if(numReg.test(id)){
              this.$router.push({
                name:'details',
                query:{
                  id:id
                }
              })
          }else{
            id=16
            this.$router.push({
                name:'details',
                query:{
                  id:id
                }
            })
          }
        },
       //搜索请求
       async handsearch(){
           this.$toast.loading({
              forbidClick: true,
              loadingType: "spinner",
           });
           const data = {
             pageNum:1,
             pageSize:10,
             productName:this.search,
           }
           const result = await getSearch(data)
           if(result.code === 0){
             this.datalist = result.data
             if(result.data.list.length == 0){
                 this.$toast.clear();
                 this.loading = false;
                 this.finished = true;
                 this.show1 = false
                 this.show = true
             }else{
                 this.$toast.clear();
                 this.finished = true;
                 this.loading = false;
                 this.list = result.data.list;
                 this.show1 = true
                 this.show = false
             }
           }else{
              this.$toast(result.data.msg); 
           }
       },
       black(){
         this.$router.go(-1)
       }
    },
    created() {
     this.search = sessionStorage.getItem("value")
     this.handsearch()
    },
  }
</script>

<style lang="scss" scoped>
.search{
  ::v-deep .van-icon{
    margin-left: 5px;
  }
  ::v-deep .van-search{
    width: 350px;
    display: inline-block;
  }
  .text{
    font-size: 12px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #999999;
    margin-left: 15px;
    margin-top: 20px;
  }
  .kong-content{
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 500px;
    div{
       img{
        width: 139px;
        height: 112px;
       }
       p{
        color: #999999;
        font-size: 12px;
        font-weight: 500;
        margin-top: 10px;
       }
    }
  }
  .content{
    margin-left: 30px;
    margin-right: 30px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    .item{
      width: 80px;
      margin-top: 20px;
       .item:after {
          display:block;
          content:"";
          width: 90px;
          height:0px;
        }
      .image{
        width: 80px;
        height: 80px;
        position: relative;
        background: #FAFBFD;
        border-radius: 10px;
        img{
          width: 80px;
          height: 80px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%);
            border-radius: 10px;
        }
      }
      .title{
        margin-top: 20px;
        font-size: 15px;
        width: 90px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #333333;
        overflow:hidden; /*溢出的部分隐藏*/
        white-space: nowrap; /*文本不换行*/
        text-overflow:ellipsis;/*ellipsis:文本溢出显示省略号（...）；clip：不显示省略标记（...），而是简单的裁切*/
      }
      .introduction{
        margin-top: 8px;
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #999999;
        width: 90px;
        overflow:hidden; /*溢出的部分隐藏*/
        white-space: nowrap; /*文本不换行*/
        text-overflow:ellipsis;/*ellipsis:文本溢出显示省略号（...）；clip：不显示省略标记（...），而是简单的裁切*/
      }
      .price {
        font-size: 12px;
        margin-top: 15px;
        span{
          font-size: 12px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #EB441E;
          span{
            font-size: 15px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #EB441E;
          }
        }
        .last{
          font-family: PingFang SC;
          font-weight: 500;
          text-decoration: line-through;
          color: #CCCCCC;
          font-size: 12px;
        }
      }
    }
  }
  .clearfix:after {
  clear: both;
  content: '';
  display: block;
  }
}
</style>